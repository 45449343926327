import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import P from "../Paragraph"
import Heading from "../Heading"
import Box from "../Box"
import Avatar from "../Avatar"
import Grid from "../Grid"

const TeamGrid = () => {
  const teamData = useStaticQuery(graphql`
    query teamQuery {
      allDatoCmsPerson(sort: { fields: position, order: ASC }) {
        nodes {
          id
          name
          title
          bio
          image {
            url
            alt
          }
        }
      }
    }
  `)

  const persons = teamData.allDatoCmsPerson.nodes

  return (
    <Grid>
      {persons.map(({ id, name, title, image }) => (
        <Box
          key={id}
          gridColumn={[" 4/ span 6", "span 6", null, "span 3"]}
          textAlign="center"
          mb={["2.5rem", null, null, 0]}
          px={[0, "1rem"]}
        >
          <Avatar ratio={1}>
            <img
              src={image.url + "?w=500&h=500&dpr=2&fit=crop&auto=format"}
              alt={name}
            />
          </Avatar>
          <Heading
            as="h3"
            color="lightGrey"
            fontSize={["md", null, "lg"]}
            my="0.75rem"
          >
            {name}
          </Heading>
          <P color="blue" my="0" fontSize={["sm", null, "md"]}>
            {title}
          </P>
        </Box>
      ))}
    </Grid>
  )
}

export default TeamGrid
