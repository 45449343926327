
import styled from "@emotion/styled"

import RatioContainer from "../RatioContainer"

// put img formatting in <Avatar /> and add src prop

const Avatar = styled(RatioContainer)`
  border-radius: 100%;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 100%;
  }
  img {
    width: 100%;
  }
`

export default Avatar