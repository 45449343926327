import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../components/Container"
import Heading from "../components/Heading"
import P from "../components/Paragraph"
import Box from "../components/Box"
import Grid from "../components/Grid"
import Flex from "../components/Flex"
import TeamGrid from "../components/TeamGrid"
import PageHeader from "../components/PageHeader"
import Wave from "../components/Wave"
import SeoHelmet from "../components/Seo"

const AboutPage = () => {
  const aboutData = useStaticQuery(graphql`
    query SellingPointsQuery {
      allDatoCmsSellingPoint(sort: { fields: position, order: ASC }) {
        nodes {
          title
          intro
        }
      }

      icons: file(name: { eq: "check" }, extension: { eq: "svg" }) {
        id
        name
        publicURL
      }
    }
  `)

  const sellingPoints = aboutData.allDatoCmsSellingPoint.nodes

  return (
    <>
      <SeoHelmet
        title="About us"
        description="Lapidus Interactive is an award-winning data visualization and web development company. We build user-friendly tools that make complex information easy to understand."
        url={`http://lapidus.se/about`}
      />
      <PageHeader
        title="About us"
        subtitle="Lapidus Interactive is an award-winning data visualization and web development company. We build user-friendly tools that make complex information easy to understand."
      />

      <Container size="md" my="7rem">
        <Heading as="h2" textAlign="center" fontSize={["lg", "xl"]}>
          {"Why Lapidus Interactive?"}
        </Heading>
        <Grid mt="5rem">
          {sellingPoints.map(({ id, title }) => (
            <Box
              key={id}
              gridColumn={["span 12", "span 6", null, "span 4"]}
              mb={["2.5rem", "5rem"]}
            >
              <Flex alignItems="center">
                <img src={aboutData.icons.publicURL} alt="check icon" />
                <Heading
                  as="h3"
                  fontSize={["sm", "md"]}
                  my="0.75rem"
                  ml="1.5rem"
                >
                  {title}
                </Heading>
              </Flex>
            </Box>
          ))}
        </Grid>
      </Container>

      <Wave variant="top1" />
      <Box bg="navy">
        <Container py="5rem">
          <Heading
            as="h2"
            fontSize={["lg", "xl"]}
            color="yellow"
            textAlign="center"
            mb="3rem"
          >
            {"Team leadership"}
          </Heading>
          <TeamGrid />
        </Container>
      </Box>
      <Wave variant="bottom1" />
      <Container size="sm" my="5rem">
        <Heading as="h2" fontSize={["lg", "xl"]}>
          {"Innovation for the public good"}
        </Heading>
        <P fontSize={["sm", "lg"]}>
          {
            "We are a team of project leaders, developers, user experience experts, designers, journalists, and statisticians. We work with clients in a variety of inspirational fields, from health and research to education and global development."
          }
        </P>
        <P fontSize={["sm", "lg"]}>
          {
            "Our work spans from leading complex open data projects to building mission critical platforms in health to setting up data journalism initiatives."
          }
        </P>
        <P fontSize={["sm", "lg"]}>
          {
            "We are technology experts, but first of all people who care deeply about improving our society. We are dedicated to building tools that have a long-lasting, positive impact in the lives of many people all over the world."
          }
        </P>
      </Container>

      <Wave variant="bottom3" color="#FFF" bg="navy" />
    </>
  )
}

export default AboutPage
